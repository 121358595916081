/*
    Animations used gloablly (TODO: move to components theme?)
    [WARNING]: Try not to add more code here unless absolutely necessary
*/

export default ({ palette }) => ({
    '@keyframes shadow-pulse': {
         '0%': { boxShadow:`0 0 0 0px ${palette.primary.main}` },
          '100%':{ boxShadow: '0 0 0 35px rgba(0, 0, 0, 0)' }
    }
});