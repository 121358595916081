const theme = {
	type: 'dark',
	scrollbar: {
		style: 'none', // oneOf['none', 'default', 'primary']
	},
	navigation: {
		number: 1,
		tablet: {
			number: 1,
		},
		desktop: {
			number: 1,
		}
	},
	typography: {
		button: {
			// 'text-transform': 'capitalize'
		},
		h1: {
			'text-transform': 'capitalize'
		},
		h2: {
			'text-transform': 'capitalize'
		},
		h3: {
			'text-transform': 'capitalize'
		},
		h4: {
			'text-transform': 'capitalize'
		},
		h5: {
			'text-transform': 'capitalize'
		},
		h6: {
			'text-transform': 'capitalize'
		},
		desktop: {
			h1: {
				'text-transform': 'capitalize'
			},
			h2: {
				'text-transform': 'capitalize'
			},
			h3: {
				'text-transform': 'capitalize'
			},
			h4: {
				'text-transform': 'capitalize'
			},
			h5: {
				'text-transform': 'capitalize'
			},
			h6: {
				'text-transform': 'capitalize'
			},
		}
	},
	scratch: {
		tablet: { height: '42' },
		desktop: { height: '42' }
	},
	palette: {
		primary: {
			lightest: "#c9c9c9",
			light: '#E43027',
			main: '#BF0A0A',
			contrastText: '#FFFFFF'
		},
		secondary: {
			light: '#757575',
			main: '#000000',
			border: '#757575',
			contrastText: '#FFFFFF'
		},
		tertiary:{
			main: "transparent",
			contrastText: "#FFFFFF"
		},
		border:{
			primary: '#373A3C',
			secondary: '#2d2d2d',
			contrast: '#FFFFFF',
		},
		background: {
			default: '#070707',
			primary: {
				main: '#000000'
			}
		},
		text:{
			primary: '#FFFFFF',
			secondary: '#959393',
			active: '#E43027',
			link: '#E43027',
		},
		site: {
			background: '#000000',
			activeText: '#E43027',
			mobile: {
				contrastText: '#E4E4E4',
				activeText: '#E43027'
			}
		},
		button: {
			contained: {
				primary: {
					background: '#BF0A0A',
					borderColor: '#BF0A0A',
					hover: {
						color: '#fff',
						background: '#373A3C',
						borderColor: '#fff',
						textDecoration: 'underline'
					},
					focus: {
						color: '#fff',
						background: '#373A3C',
						borderColor: '#fff',
						textDecoration: 'underline'
					}
				}
			},
			outline: {
				quintary: {
					borderColor: '#373A3C',
					hover: {
						borderColor: '#BF0A0A',
						textDecoration: 'none',
					},
					focus: {
						borderColor: '#BF0A0A',
						textDecoration: 'none',
					}
				}
			},
			text: {
				primary: {
					color: '#E43027',
					hover: {
						color: '#e6443c' // lighten #E43027
					},
					focus: {
						color: '#e6443c' // lighten #E43027
					}
				}
			}
		}
	},
	overrides: {
		MuiFormLabel: {
			root: {
				"&$focused": {
					color: '#E43027',
				}
			},
		},
	},
}

module.exports = theme
