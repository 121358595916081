import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { I18nextProvider } from 'react-i18next'
import each from 'lodash/each'
import CssBaseline from '@material-ui/core/CssBaseline'
import ThemeProvider from 'tcweb-material-components/core/themeProvider'
import GlobalStyles from 'tcweb-material-components/core/globalStyles'
import get from 'tcweb-material-components/core/poly/get'

import usePushNotification from '../../hooks/usePushNotification'
import { getLocalesFromWPMLLocale, defaultLocale } from '../../utilities/locale'
import i18n from '../../utilities/i18n'
import PushNotificationContext from '../context/pushNotificationContext'
import LocaleContext from '../context/localeContext'
import { decodeText } from '../../utilities/helpers'

import theme from '../../theme/dark/'
import styles from '../../styles'
import '../../styles/vendor/gutenberg-style.css'
import '../../styles/vendor/gutenberg-theme.css'
import '../../styles/vendor/cover-block.css'

const initI18n = translations => {
    if (!i18n.hasResourceBundle('en-us', 'title')) {
        i18n.addResourceBundle('en-us', 'title')
        each(translations, translation => {
            const translatedList = [
                ...translation.node.translated,
                {  // Add en_US. Not in edge.node.translated
                    locale: get(translation, 'node.locale', defaultLocale),
                    title: translation.node.title,
                    slug: translation.node.slug
                }
            ]

            each(translatedList, translated => {
                each(getLocalesFromWPMLLocale(translated.locale.locale), locale => {
                    i18n.addResource(locale, 'title', translation.node.title, translated.title)
                    i18n.addResource(locale, 'slug', translation.node.slug, translated.title)
                })
            })
        })
    }
}

const Layout = props => {
    const { children, pageContext } = props
    const pushNotificationData = usePushNotification()

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    keywords
                    socialAlt
                    siteUrl
                }
            }
            socialImage: file(relativePath: { regex: "/social\/gearsOfWar/" }) {
                publicURL
            }
            translations: allWpSiteStringTranslation (filter: {locale: {locale: {in: ["en_US", null]}}}){ # For WP sites with no translations, match null to get english content for slug lookup
                edges {
                    node {
                        slug
                        title
                        locale { locale }
                        translated {
                            title
                            locale { locale }
                        }
                    }
                }
            }
        }
    `)

    if(!!data.translations){
        initI18n(data.translations.edges)

        if (i18n.language !== props.pageContext.locale) {
            i18n.changeLanguage(props.pageContext.locale)
        }
    }

    const cleanTitle = !!props.data && !!props.data.page && !!props.data.page.title ? decodeText(props.data.page.title) : null

    const localeData = {
        locale: pageContext.locale,
        cmsLocale: pageContext.cmsLocale,
        isImplicit: pageContext.isImplicit
    }

    return (
        <>
            <Helmet
                title={!!pageContext && !!cleanTitle ? `${data.site.siteMetadata.title} | ${cleanTitle}` : data.site.siteMetadata.title}
                meta={[
                    // General Data
                    { name: 'description', content: data.site.siteMetadata.description },
                    { name: 'keywords', content: data.site.siteMetadata.keywords },
                    { name: 'robots', content: 'Index' },
                    {
                        name: 'viewport',
                        content: 'width=device-width, height=device-height, initial-scale=1.0, shrink-to-fit=no, viewport-fit=cover'
                    },
                    // Twitter Card Data
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: data.site.siteMetadata.title },
                    { name: 'twitter:description', content: data.site.siteMetadata.description },
                    { name: 'twitter:image', content: `${data.site.siteMetadata.siteUrl}${data.socialImage.publicURL}` },
                    { name: 'twitter:image:alt', content: data.site.siteMetadata.socialAlt },
                    // Open Graph Data
                    { property: 'og:title', content: data.site.siteMetadata.title },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:url', content: data.site.siteMetadata.siteUrl },
                    { name: 'image', property: 'og:image', content: `${data.site.siteMetadata.siteUrl}${data.socialImage.publicURL}` },
                    { property: 'og:image:alt', content: data.site.siteMetadata.socialAlt },
                    { property: 'og:description', content: data.site.siteMetadata.description },
                    { property: 'og:site_name', content: data.site.siteMetadata.title }
                ]}
            >
                <html lang={props.pageContext.locale} />
                <link href='https://use.fontawesome.com/releases/v5.0.1/css/all.css' rel='preload' as='style' />
            </Helmet>

            <LocaleContext.Provider value={localeData}>
                <PushNotificationContext.Provider value={pushNotificationData}>
                    <I18nextProvider i18n={i18n}>
                        <ThemeProvider type={theme.type || 'dark'} theme={theme} parent>
                            <GlobalStyles styles={styles} />
                            <CssBaseline />
                            {children}
                        </ThemeProvider>
                    </I18nextProvider>
                </PushNotificationContext.Provider>
            </LocaleContext.Provider>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
