import keys from 'tcweb-material-components/core/poly/keys'

/*
    Layouts / positioning of page elements
    [WARNING]: Try not to add more code here unless absolutely necessary
*/

const ALIGNMENT_OPTIONS = ['left', 'right', 'center']

const getTextAlignClasses = (breakpoints) =>
    keys(breakpoints.values).reduce((styles, breakpoint) => {
        return {
            ...styles,
            [breakpoints.up(breakpoint)]: {
                ...ALIGNMENT_OPTIONS.reduce((acc, alignment) => {
                    return {
                        ...acc,
                        [`.align-text-${breakpoint}-${alignment}`]: {
                            textAlign: alignment
                        }
                    }
                }, {})
            }
        }
    }, {})

export default ({ gutters, breakpoints }) => ({
    '.alignfull': {
        marginRight: 0,
        marginLeft: 0,
        width: `calc(100% + (${gutters.page.xs} * 2))`,
        maxWidth: `calc(100% + (${gutters.page.xs} * 2))`,

        // Tablet
        [breakpoints.only('sm')]: {
            marginLeft: `-${gutters.page.sm}`,
            width: `calc(100% + (${gutters.page.sm} * 2))`,
            maxWidth: `calc(100% + (${gutters.page.sm} * 2))`
        },

        [breakpoints.up('md')]: {
            marginLeft: `-${gutters.page.md}`,
            width: `calc(100% + (${gutters.page.md} * 2))`,
            maxWidth: `calc(100% + (${gutters.page.md} * 2))`
        },

        // Desktop
        [breakpoints.up('lg')]: {
            marginLeft: `-${gutters.page.lg}`,
            width: `calc(100% + (${gutters.page.lg} * 2))`,
            maxWidth: `calc(100% + (${gutters.page.lg} * 2))`
        }
    },
    '.alignwide': {
        width: '100%',
        paddingLeft: `calc(${gutters.page.sm} / 2)`,
        paddingRight: `calc(${gutters.page.sm} / 2)`,

        // Desktop
        [breakpoints.up('lg')]: {
            paddingLeft: `${gutters.page.lg}`,
            paddingRight: `${gutters.page.lg}`
        }
    },

    ...getTextAlignClasses(breakpoints),

    '.hero-root, .page-root': {
        '& > .alignfull:not(.hero-simplified-swap)': {
            marginLeft: 0
        },
        '& :not(.kt-tab-inner-content-inner)': {
            '& > .alignfull': {
                marginLeft: 0
            }
        }
    },

    '.column-swap': {
        '.wp-block-column': {
            margin: 0
        }
    }
})
