/*
    Support for any styles we need but dont have swaps for.
    [WARNING]: Try not to add more code here unless absolutely necessary
*/

export default ({ palette, typography, breakpoints }) => ({
    // Columns
    '.wp-block-columns' : {
        marginBottom: 'inherit'
    },
    [breakpoints.down('sm')]:{
        '.wp-block-columns':{
            flexDirection: 'column'
        }
    },

    // Figure
    'figure': {
        margin: 0,
        'a + figcaption, button + figcaption ':{
            ...typography.h4,
            [breakpoints.up('md')]:{ ...typography.desktop.h4 },
            fontSize: '1.33em !important',
            color: `${palette.text.primary} !important`
        }
    },

    // Image
    '.wp-block-image img[src$=".svg"]': { width: '100%' },

    // Remove border-box to allow background to work
    '.wp-block-group': { display: 'inline-block' },

    // Change padding for headings with background
    'h1.MuiTypography-root.has-background': { paddingTop: '1em', paddingBottom: '1em' },
    'h2.MuiTypography-root.has-background': { paddingTop: '1em', paddingBottom: '1em' },
    'h3.MuiTypography-root.has-background': { paddingTop: '.5em', paddingBottom: '.5em' },
    'h4.MuiTypography-root.has-background': { paddingTop: '.5em', paddingBottom: '.5em' },
    'h5.MuiTypography-root.has-background': { paddingTop: '.5em', paddingBottom: '.5em' },
    'h6.MuiTypography-root.has-background':{ paddingTop: '.5em', paddingBottom: '.5em' }
})