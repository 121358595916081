export default ({ breakpoints }) => {
    return {
        '.page-games': {
            '.bg-default': {
                padding: 0,
                '&[class*="wp-block-custom-uniform-grid-block"]': {
                    width: '100%',
                    paddingLeft: 0,
                    paddingRight: 0
                },
                [breakpoints.up('md')]: {
                    paddingLeft: '1em',
                    paddingRight: '1em'
                }
            }
        },

        '.page-gears-of-war-ultimate': {
            '&[data-current-tab="0"]': {
                paddingBottom: 0
            }
        },

        [breakpoints.only('xs')]: {
            '.page-gears-of-war-ultimate, .page-gears-of-war-4': {
                '.hero-swap': {
                    '.column-swap': {
                        '.wp-block-image': {
                            height: '50px'
                        },

                        '& > .wp-block-column:last-child': {
                            gridColumnStart: 1,
                            gridColumnEnd: 4,

                            '.wp-block-image': {
                                magin: 'auto',
                                width: '50%'
                            }
                        }
                    }
                }
            }
        }
    }
}
