import { darken, lighten } from '@material-ui/core/styles/colorManipulator'

/*
    Scrollbar for pages
    [WARNING]: Try not to add more code here unless absolutely necessary
*/

const commonStyles = (spacing) => ({
    '&::-webkit-scrollbar': {
        width: spacing(1.5), // Vertical scrollbar
        height: spacing(1)   // Horizontal scrollbar
    },
    '&::-webkit-scrollbar-corner': {
        background: 'none'
    }
})

const thumbCommonStyles = (highContrast, spacing) => ({
    borderRadius: spacing(.5),
    [highContrast.mediaQuery('active')]: {
        backgroundColor: 'ButtonText',
        '&:active, &:hover': {
            background: 'Highlight'
        }
    }
})

// Default Light / Dark
const defaultStyles = ({ palette, spacing, accessibility: { highContrast } }) => ({
    ...commonStyles(spacing),

    '&::-webkit-scrollbar-track': {
        backgroundColor: darken(palette.background.default, .8)
    },
    '&::-webkit-scrollbar-track-piece': {
        borderLeft: `1px solid ${darken(palette.background.default, .8)}`
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: palette.type === 'dark' ? lighten(palette.background.default, .4) : palette.background.default,
        borderLeft: `1px solid ${darken(palette.background.default, .8)}`,
        borderRight: `1px solid ${darken(palette.background.default, .8)}`,
        '&:active, &:hover': {
            backgroundColor: palette.type === 'dark' ? lighten(palette.background.default, .2) : darken(palette.background.default, .3),
        },
        ...thumbCommonStyles(highContrast, spacing)
    }
})

// Primary Light / Dark
const primaryStyles = ({ palette, spacing, accessibility: { highContrast } }) => ({
        ...commonStyles(spacing),

        '&::-webkit-scrollbar-track': {
            backgroundColor: darken(palette.background.default, .8)
        },
        '&::-webkit-scrollbar-track-piece': {
            borderLeft: `1px solid ${darken(palette.background.default, .8)}`
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: lighten(palette.primary.main, .15),
            borderLeft: `1px solid ${darken(palette.background.default, .8)}`,
            borderRight: `1px solid ${darken(palette.background.default, .8)}`,
            '&:active, &:hover': {
                backgroundColor: darken(palette.primary.main, .2)
            },
            ...thumbCommonStyles(highContrast, spacing)
        }
})

export default (theme) => {
    switch(theme.scrollbar.style) {
        case 'primary':
            return primaryStyles(theme)
        case 'default':
            return defaultStyles(theme)
        default: // none
            return {}
    }
}