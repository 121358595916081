import { useEffect, useState } from 'react'

import { encodeQueryData, urlBase64ToUint8Array } from '../utilities/helpers'

const PUSH_NOTIFICATION_ENABLED = !!process.env.GATSBY_FEATURE_PUSH_NOTIFICATION && JSON.parse(process.env.GATSBY_FEATURE_PUSH_NOTIFICATION.toLowerCase()) === true

const SUBSCRIBE_URL = `${process.env.GATSBY_FUNCTIONS_BASEURL}/savesubscription`
const UNSUBSCRIBE_URL = `${process.env.GATSBY_FUNCTIONS_BASEURL}/removesubscription`

const checkFeature = (callback, rejectMessage, resolveMessage) => () => new Promise((resolve, reject) => {
    if (callback()) {
        resolve(resolveMessage)
    } else {
        reject(rejectMessage)
    }
})

export const checkNotificationSupport = checkFeature(() => 'Notification' in window, 'Browser does not support notifications.')
export const checkServiceWorkerSupport = checkFeature(() => 'serviceWorker' in navigator, 'Browser does not support service workers.')
export const checkPushManagerSupport = checkFeature(() => 'PushManager' in window, 'Browser does not support push managers.')

const convertSubscriptionToObject = (subscription) => {
    if (!subscription) return {}

    const subscriptionString = JSON.stringify(subscription)
    return JSON.parse(subscriptionString)
}

const getWPSlug = () => process.env.GATSBY_WORDPRESS_DOMAIN.split('/').pop()

const subscribeForNotifications = (registration) => {
    const options = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(process.env.GATSBY_APPLICATION_SERVER_KEY)
    }

    return registration.pushManager.subscribe(options)
        .then((subscription) => {
            const subscriptionObj = convertSubscriptionToObject(subscription)

            // Send subscription data to BE
            return fetch(SUBSCRIBE_URL, {
                method: 'POST',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    subscription: {
                        endpoint: subscriptionObj.endpoint,
                        keys: subscriptionObj.keys
                    },
                    site: getWPSlug()
                })
            })
                .then((response) => {
                    if (!response.ok) {
                        subscription.unsubscribe()
                        throw Error('Failed subscribing')
                    }
                    return subscription
                })
                .catch((e) => {
                    subscription.unsubscribe()
                    throw Error(e)
                })
        })
}

const unsubscribe = (subscription) => {
    if (!subscription) return

    const subscriptionObj = convertSubscriptionToObject(subscription)

    return fetch(UNSUBSCRIBE_URL, {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            subscription: {
                endpoint: subscriptionObj.endpoint,
                keys: subscriptionObj.keys
            },
            site: getWPSlug()
        })
    })
        .then((response) => {
            if (!response.ok) throw Error('Failed unsubscribing')
            return subscription.unsubscribe()
        })
        .catch((e) => {
            subscription.unsubscribe()
            throw Error(e)
        })
}

const QUERY_DATA = encodeQueryData({
    instrumentationKey: process.env.GATSBY_APP_INSIGHTS_INSTRUMENTATION_KEY
})

const usePushNotification = () => {
    const [canReceivePushNotification, setCanReceivePushNotification] = useState(null)
    const [serviceWorkerRegistration, setServiceWorkerRegistration] = useState(null)
    const [subscription, setSubscription] = useState(null)
    const [permissionResult, setPermissionResult] = useState(null)

    const _registerServiceWorker = (registerWorker = true) => serviceWorkerRegistration
        ? Promise.resolve(serviceWorkerRegistration)
        : navigator.serviceWorker.getRegistration()
            .then((currentRegistration) => {
                if (currentRegistration) {
                    setServiceWorkerRegistration(currentRegistration)
                    return currentRegistration
                }

                if (!registerWorker) {
                    return null
                }

            return navigator.serviceWorker.register(`/sw.js?${QUERY_DATA}`).then((registration) => {
                    setServiceWorkerRegistration(registration)
                    return registration
                })
            })

    const _subscribe = (subscribe = true) => subscription
        ? Promise.resolve(subscription)
        : _registerServiceWorker(subscribe)
            .then((registration) => {
                if (!registration) return

                return registration.pushManager.getSubscription()
                    .then((currentSubscription) => {
                        if (currentSubscription) {
                            setSubscription(currentSubscription)
                            return currentSubscription
                        }

                        if (!subscribe) {
                            return null
                        }

                        return subscribeForNotifications(registration)
                            .then((subscription) => {
                                setSubscription(subscription)
                                return subscription
                            })
                    })
            })

    const askPermission = () => {
        return new Promise((resolve, reject) => {
            if (!('Notification' in window)) {
                reject()
                return
            }

            const permissionResult = Notification.requestPermission(resolve, reject)

            if (permissionResult) {
                permissionResult.then(resolve, reject)
            }
        })
            .then((permissionResult) => {
                setPermissionResult(permissionResult)
                return permissionResult
            })
    }

    const _unsubscribe = () => _registerServiceWorker(false)
        .then((registration) => {
            if (registration) {
                return registration.pushManager.getSubscription()
                    .then(unsubscribe)
                    .then(() => {
                        registration.unregister()
                        setServiceWorkerRegistration(null)
                        setSubscription(null)
                    })

            }
        })

    useEffect(() => {
        if (PUSH_NOTIFICATION_ENABLED) {
            // Check all necessary brower functionality
            Promise.all([
                checkNotificationSupport(),
                checkPushManagerSupport(),
                checkServiceWorkerSupport()
            ]).then(() => {
                setCanReceivePushNotification(true)
                setPermissionResult(Notification.permission)
            }).catch(() => {
                setCanReceivePushNotification(false)
            })
        } else {
            setCanReceivePushNotification(false)
        }
    }, [])

    useEffect(() => {
        if (!canReceivePushNotification) return

        switch(permissionResult) {
        case 'granted':
            _subscribe(false)
            break
        case 'default':
        case 'denied':
            // Unregister the service worker and unsubscribe from the push service
            _unsubscribe()
            break
        default:
            break
        }
    }, [canReceivePushNotification, permissionResult])

    return {
        subscription,
        permissionResult,
        serviceWorkerRegistration,
        canReceivePushNotification,
        askPermission,
        subscribe: _subscribe,
        unsubscribe: _unsubscribe
    }
}

export default usePushNotification
