const defaultLocale = 'en-us'
const defaultWPMLLocale = 'en_US'

const localesList = [ 'en', 'en-us', 'en-ca', 'en-au', 'en-in', 'en-ie', 'en-nz', 'en-sg', 'en-za', 'en-gb', 'es', 'es-es', 'es-mx', 'es-cl', 'es-co', 'fr', 'fr-fr', 'fr-be', 'fr-ca', 'fr-ch', 'de-de', 'it', 'it-it', 'ja', 'ja-jp', 'ko', 'ko-kr', 'pl', 'pl-pl', 'pt', 'pt-br', 'ru', 'ru-ru', 'zh-cn', 'zh-tw', 'ar-ae', 'ar-sa', 'tr-tr']

const getLocaleFromWPMLLocale = WPMLLocale => getLocalesFromWPMLLocale(WPMLLocale).shift()

const getLocalesFromWPMLLocale = WPMLLocale => {
    switch (WPMLLocale) {
        case 'en_US':
            return ['en-us', 'en-ca', 'en-au', 'en-in', 'en-ie', 'en-nz', 'en-za', 'en-gb', 'en-sg', 'tr-tr', 'ar-ae', 'ar-sa']
        case 'es_ES':
            return ['es-es']
        case 'es_MX':
            return ['es-mx', 'es-cl', 'es-co'] // Latam closer to es-mx than es-es
        case 'fr_FR':
            return ['fr-fr', 'fr-be', 'fr-ca', 'fr-ch']
        case 'de_DE':
            return ['de-de']
        case 'it_IT':
            return ['it-it']
        case 'ja':
            return ['ja-jp']
        case 'ko_KR':
            return ['ko-kr']
        case 'pl_PL':
            return ['pl-pl']
        case 'pt_BR':
            return ['pt-br']
        case 'ru_RU':
            return ['ru-ru']
        case 'zh_CN':
            return ['zh-cn']
        case 'zh_TW':
            return ['zh-tw']
        default:
            return ['en-us']
    }
}

const apiLocalesList = ['ar', 'de', 'en', 'es', 'es-es', 'fr', 'it-it', 'ja-jp', 'ko-kr', 'pl-pl', 'pt-br', 'ru-ru', 'tr-tr', 'zh', 'zh-cn']

const getAPILocaleFromLocale = locale => {
    switch (locale) {
        case 'ar-ae':
        case 'ar-sa':
            return 'ar'
        case 'de-de':
            return 'de'
        case 'en-us':
        case 'en-ca':
        case 'en-au':
        case 'en-in':
        case 'en-ie':
        case 'en-nz':
        case 'en-sg':
        case 'en-za':
        case 'en-gb':
            return 'en'
        case 'es-mx':
        case 'es-cl':
        case 'es-co':
            return 'es'
        case 'es-es':
            return 'es-es'
        case 'fr-fr':
        case 'fr-be':
        case 'fr-ca':
        case 'fr-ch':
            return 'fr'
        case 'it-it':
            return 'it-it'
        case 'ja-jp':
            return 'ja-jp'
        case 'ko-kr':
            return 'ko-kr'
        case 'pl-pl':
            return 'pl-pl'
        case 'pt-br':
            return 'pt-br'
        case 'ru-ru':
            return 'ru-ru'
        case 'tr-tr':
            return 'tr-tr'
        case 'zh-cn':
            return 'zh-cn'
        case 'zh-tw':
            return 'zh'
        default:
            return 'en'
    }
}

const getLocaleFromPath = url => {
    let urlParts = url.split('/')

    if (urlParts[0] === '') {
        urlParts.shift()
    }

    return localesList.includes(urlParts[0]) ? urlParts[0] : ''
}

const getLocaleNameFromUrl = url => {
    let urlParts = url.split('/')

    if (urlParts[0] === '') {
        urlParts.shift()
    }

    return getLocaleName(urlParts[0])
}

const getLocaleName = locale => {
    switch (locale) {
        case 'en-us':   return 'United States - English'
        case 'en-ca':   return 'Canada - English'
        case 'en-au':   return 'Australia - English'
        case 'en-in':   return 'India - English'
        case 'en-ie':   return 'Ireland - English'
        case 'en-nz':   return 'New Zealand - English'
        case 'en-sg':   return 'Singapore - English'
        case 'en-za':   return 'South Africa - English'
        case 'en-gb':   return 'United Kingdom - English'
        case 'en':      return 'English'
        case 'es-es':   return 'España - Español'
        case 'es-mx':   return 'México - Español'
        case 'es-cl':   return 'Chile - Español'
        case 'es-co':   return 'Colombia - Español'
        case 'es':      return 'Español'
        case 'fr-fr':   return 'France - Français'
        case 'fr-be':   return 'Belgique - Français'
        case 'fr-ca':   return 'Canada - Français'
        case 'fr-ch':   return 'Suisse - Français'
        case 'fr':      return 'Français'
        case 'de-de':   return 'Deutschland - Deutsch'
        case 'it-it':   return 'Italia - Italiano'
        case 'it':      return 'Italiano'
        case 'ja-jp':   return '日本 - 日本語'
        case 'ja':      return '日本 - 日本語'
        case 'ko-kr':   return '대한민국 - 한국어'
        case 'ko':      return '대한민국 - 한국어'
        case 'pl-pl':   return 'Polska - Polski'
        case 'pl':      return 'Polska - Polski'
        case 'pt-br':   return 'Brasil - Português'
        case 'pt':      return 'Português'
        case 'ru-ru':   return 'Россия - Русский'
        case 'ru':      return 'Россия - Русский'
        case 'zh-cn':   return '中国 – 简体中文'
        case 'zh-tw':   return '台灣 - 繁體中文'
        case 'ar-ae':   return 'الإمارات العربية المتحدة - العربية'
        case 'ar-sa':   return 'المملكة العربية السعودية - العربية'
        case 'tr-tr':   return 'Türkiye - Türkçe'
        default:        return 'United States - English'
    }
}

const removeLocaleFromPath = url => {
    let urlParts = url.split('/')

    if (urlParts[0] === '') {
        urlParts.shift()
    }

    if (localesList.includes(urlParts[0])) {
        urlParts.shift()
        return `/${urlParts.join('/')}`
    }

    return url
}

exports.defaultLocale = defaultLocale
exports.defaultWPMLLocale = defaultWPMLLocale
exports.apiLocalesList = apiLocalesList
exports.localesList = localesList
exports.getLocaleFromPath = getLocaleFromPath
exports.getLocaleNameFromUrl = getLocaleNameFromUrl
exports.getLocaleName = getLocaleName
exports.getLocaleFromWPMLLocale = getLocaleFromWPMLLocale
exports.getLocalesFromWPMLLocale = getLocalesFromWPMLLocale
exports.getAPILocaleFromLocale = getAPILocaleFromLocale
exports.removeLocaleFromPath = removeLocaleFromPath