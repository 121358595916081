import parse from 'html-react-parser'

const isInternalLink = (url) => (url ? url.startsWith('/') || url.startsWith(process.env.GATSBY_SITE_URL) : false)

const getSlugFromFullUrl = (url) => (url && url.startsWith(process.env.GATSBY_SITE_URL) ? url.replace(process.env.GATSBY_SITE_URL, '') : url)

const decodeText = (text) => {
    const cleanText = parse(text)
    if (!!cleanText[0] && !!cleanText[0].props && !!cleanText[0].props.children) {
        return cleanText[0].props.children
    }
    return cleanText
}

const encodeQueryData = (data) => {
    const ret = []
    for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
    return ret.join('&')
}

const splitQueryString = (url) => {
    const query = {}
    const urlParts = url.split('?').pop().split('&')

    for (let part of urlParts) {
        const queryParts = part.split('=')
        query[decodeURIComponent(queryParts[0])] = decodeURIComponent(queryParts.pop() || true)
    }

    return query
}

const copyText = (text, node) => {
    if (node) {
        if (document.body.createTextRange) {
            const range = document.body.createTextRange()
            range.moveToElementText(node)
            range.select()
        } else if (window.getSelection) {
            const selection = window.getSelection()
            const range = document.createRange()
            range.selectNodeContents(node)
            selection.removeAllRanges()
            selection.addRange(range)
        }
    }

    // Hightlighting is required for copy to work in this way (old browsers)
    if (!navigator.clipboard) {
        return document.execCommand('copy')
    }

    return navigator.clipboard.writeText(text)
}

const formatSkillTranslation = function (str, ...values) {
    var a = str
    for (var k in values) {
        if(!values[k]) continue
        a = a.replace(new RegExp("\\{" + k + "\\}", 'g'), values[k])
    }
    return a
}

const stripProtocol = (url) => url.replace(/(^\w+:|^)\/\//, '')

const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; i++) {
        outputArray[i] = rawData.charCodeAt(i)
    }

    return outputArray
}

const forceDownload = (blob, filename) => {
    var a = document.createElement('a')
    a.download = filename
    a.href = blob
    // For Firefox https://stackoverflow.com/a/32226068
    document.body.appendChild(a)
    a.click()
    a.remove()
}

// Current blob size limit is around 500MB for browsers
const downloadResource = (url, filename) => {
    if (!filename) filename = url.split('\\').pop().split('/').pop();
    fetch(url, {
        headers: new Headers({
          'Origin': location.origin
        }),
        mode: 'cors'
      })
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        forceDownload(blobUrl, filename);
      })
      .catch(e => console.error(e));
}

// Add wordpress_site and locale field for Gears of War posts
const normalizeGearsPosts = (posts, siteName) =>
    posts.map((post) => ({
        node: {
            ...post.node,
            wordpress_site: siteName,
            locale: {
                locale: 'en_US'
            }
        }
    }))

// Returns a list of normalized gatsby-source-graphql posts' fields to match those from gatsby-source-wordpress
const normalizePosts = (posts, siteName) =>
    posts.map((post) => ({
        node: {
            ...post.node,
            wordpress_site: siteName,
            wpml_current_locale: post.node.locale ? normalizeLocale(post.node.locale.locale) : undefined,
            sticky: post.node.isSticky,
            featuredImage: post.node.featuredImage
                ? {
                      node: {
                          altText: post.node.featuredImage.node.altText,
                          sourceUrl:
                              post.node.locale.locale === 'en_US'
                                  ? post.node.featuredImage.node.sourceUrl
                                  : normalizeGears5MediaUrls(post.node.featuredImage.node.sourceUrl, siteName)
                      }
                  }
                : {},
            wpml_translations:
                post.node.translated.length > 0
                    ? post.node.translated.map((translation) => ({
                          content: translation.content,
                          title: translation.title,
                          locale: translation.locale.locale
                      }))
                    : []
        }
    }))

// Convert the locale field to match the format in context
// e.g. "en_US" => "en-us"
const normalizeLocale = (locale) => {
    if (!locale.includes('_')) return locale
    const splitLocale = locale.split('_')
    splitLocale[1] = splitLocale[1].toLowerCase()
    return splitLocale.join('-')
}

// Normalize media urls for non-english posts
const normalizeGears5MediaUrls = (url, siteName) => {
    const TACTICS_URL = 'https://cdn.gearstactics.com/media/'
    const GEARS_5_URL = 'https://cdn.gears5.com/media'
    const slicedUrl = url.split('/').slice(3)
    if (siteName === 'gears-5') {
        slicedUrl.unshift(GEARS_5_URL)
    } else if (siteName === 'gears-tactics') {
        slicedUrl.unshift(TACTICS_URL)
    }
    return slicedUrl.join('/')
}

export {
    isInternalLink,
    getSlugFromFullUrl,
    decodeText,
    encodeQueryData,
    copyText,
    formatSkillTranslation,
    splitQueryString,
    stripProtocol,
    urlBase64ToUint8Array,
    downloadResource,
    normalizePosts,
    normalizeGearsPosts
}
