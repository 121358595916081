import React from 'react'

import Layout from '../../components/layout/layout'

const detectWebpSupport = () => {
    if (typeof window !== 'undefined') {
        var webp = new Image()
        webp.src = 'data:image/webp;base64,UklGRjIAAABXRUJQVlA4ICYAAACyAgCdASoBAAEALmk0mk0iIiIiIgBoSygABc6zbAAA/v56QAAAAA=='
        webp.onerror = () => {
            document.body.classList.add('no-webp')
        }
        webp.onload = () => {
            document.body.classList.add('webp')
        }
    }
}

export default ({ element, props }) => {
    detectWebpSupport()

    return <Layout {...props}>{element}</Layout>
}
