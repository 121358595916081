module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true},"disableAutoprefixing":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.live.gearsofwar.com/franchise/graphql","verbose":true,"debug":{"graphql":{"showQueryVarsOnError":true,"writeQueriesToDisk":true,"showQueryOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"production":{"allow404Images":true,"hardCacheMediaFiles":false},"schema":{"typePrefix":"WPSite","requestConcurrency":10,"timeout":60000,"perPage":70,"queryDepth":15,"circularQueryLimit":5,"previewRequestConcurrency":5},"html":{"imageMaxWidth":3840,"useGatsbyImage":false,"fallbackImageMaxWidth":100,"imageQuality":90,"createStaticFiles":true,"generateWebpImages":false},"type":{"User":{"exclude":true},"UserRole":{"exclude":true},"Comment":{"exclude":true},"PostFormat":{"exclude":true},"Menu":{"excludeFieldNames":["locations"]},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gears of War","short_name":"Gears of War","start_url":"/","display":"standalone","icon":"static/favicon.png","background_color":"#070707","theme_color":"#070707","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"963dcd18171c55442aa85b04110bac3c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
