import { useTheme } from '@material-ui/core/styles'
import animations from './common/animations'
import base from './common/base'
import backgrounds from './common/backgrounds'
import layout from './common/layout'
import typography from './common/typography'
import wordpress from './common/wordpress'
import scrollbar from './common/scrollbar'
import overrides from './vendor/overrides'
import games from './common/games'

/* ===  Global styles ===
  - WP blocks that haven't been swapped
  - Custom classes for blocks
  - Heading classes (eg: 'h1', 'h2' etc.)
*/


export default () => {
    const theme = useTheme()

    return {
        ...animations(theme),
        ...base(theme),
        ...backgrounds(theme),
        ...layout(theme),
        ...typography(theme),
        ...wordpress(theme),
        ...overrides(theme),
        ...scrollbar(theme),
        ...games(theme)
    }
}