import fetch from 'isomorphic-fetch'
import { ApolloClient, InMemoryCache } from "@apollo/client"

const cache = new InMemoryCache({
    cacheRedirects: {
        Query: {
            profile: (_, { id }, { getCacheKey }) => getCacheKey({ __typename: 'ProfileType', ID:id })
        }
    }
})

const defaultApolloOptions = {
    // Provide required constructor fields
    cache,

    // Provide some optional constructor fields
    queryDeduplication: false,
    defaultOptions: {
        query: {
            fetchPolicy: 'cache-and-network'
        }
    }
}

export const client = new ApolloClient({ ...defaultApolloOptions })
export const ssrClient = new ApolloClient({ ...defaultApolloOptions, fetch })
