import React from 'react'
import ReactDOM from 'react-dom'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js'
import { ApolloProvider } from '@apollo/client'

import { client } from './src/graphql/apollo/client'

const clickPluginInstance = new ClickAnalyticsPlugin()
const clickPluginConfig = {
  autoCapture: true,
  urlCollectQuery: true,
  dataTags: {
    useDefaultContentNameOrId: true
  },
  dropInvalidEvents: true // Flag to drop events that do not have custom event names, no parentId and no data in content
}

const configObj = {
    instrumentationKey: process.env.GATSBY_APP_INSIGHTS_INSTRUMENTATION_KEY,
    extensions: [clickPluginInstance],
    extensionConfig: {
        [clickPluginInstance.identifier]: clickPluginConfig
    },
    /* ...Other Configuration Options... */
}

export { default as wrapPageElement } from './src/graphql/apollo/wrap-page-element'

export const wrapRootElement = ({ element }) => <ApolloProvider client={client}>{element}</ApolloProvider>

export const onInitialClientRender = () => {
    if (process.env.GATSBY_APP_INSIGHTS_INSTRUMENTATION_KEY) {
        const appInsights = new ApplicationInsights({ config: configObj })
        appInsights.loadAppInsights()
        window.appInsights = appInsights
    }
}

export const onRouteUpdate = ({ loadPageSync, location }) => {
    const page = loadPageSync(location.pathname)
    const pageData = page && page.json
    !!window.appInsights && window.appInsights.trackPageView({ properties: { locale: pageData && pageData.pageContext.locale } })
}

export const replaceHydrateFunction = () => {
    return (element, container, callback) => {
        ReactDOM.render(element, container, callback)
    }
}
