import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
    debug: !!process.env.GATSBY_I18_DEBUG && JSON.parse(process.env.GATSBY_I18_DEBUG.toLowerCase()) === true,
    fallbackLng: 'en-us',
    defaultNS: 'title',
    lowerCaseLng: true,
    react: {
        useSuspense: false
    },
    interpolation: {
        escapeValue: false // react already safes from xss
    }
})

export default i18n
